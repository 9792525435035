<template>
    <div>
        <v-radio-group v-model="rollCallStatus" row>
          <v-radio value="present" label="Present" :color="color"></v-radio>
          <v-radio value="late" label="Late" :color="color"></v-radio>
          <v-radio value="no show" label="No Show" :color="color"></v-radio>
        </v-radio-group>
    </div>
</template>

<script>
export default {
  name: "RollCallOptions",
  props: {
    volunteerShiftID: Number,
    rollCallValue: String,
    shiftID: Number,
    color: String,
  },
  data() {
      return {
        rollCallStatus: this.rollCallValue,
      }
  },
  methods: {
  },
  mounted() {},
  watch: {
    rollCallStatus() {
      if(this.rollCallStatus && this.volunteerShiftID) {
        // Update the volunteer shift with the new roll_call value
        this.$store.dispatch("volunteer_shifts/updateVolunteerShift", {
            id: this.volunteerShiftID,
            roll_call: this.rollCallStatus,
        });
        
        // "Refresh" the lists
        this.$store.dispatch("shifts/getShift", this.shiftID)
      }
    }
  }
};
</script>
