<template>
  <v-card outlined class="name-card" v-if="item.volunteer">
    <v-card-title class="pb-0">
      <router-link :to="{ name: 'VolunteerShow', params: { id: item.volunteer.id } }" target="_blank">
        <v-menu v-if="item.note" offset-y open-on-hover>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              {{ item.volunteer.first_name + " " + item.volunteer.last_name }}
            </span>
          </template>
          <v-list>
            <v-list-item>
              {{ item.note }}
            </v-list-item>
          </v-list>
        </v-menu>
        <div v-else>
          {{ item.volunteer.first_name + " " + item.volunteer.last_name }}
        </div>
      </router-link>
    </v-card-title>

    <v-card-text class="d-flex align-center">
      <v-chip
        v-if="item.volunteer && item.volunteer.tshirt_size"
        class="mr-2 mb-2"
        color="blue-grey"
        text-color="white"
      >
        <v-avatar left>
          <v-icon>mdi-tshirt-crew</v-icon>
        </v-avatar>
        <span class="capitalize">{{ item.volunteer.tshirt_size }}</span>
      </v-chip>

      <v-chip
        v-if="item.volunteer.city && item.volunteer.state"
        class="mr-2 mb-2"
        color="blue-grey"
        text-color="white"
        v-on="on"
      >
        <v-avatar left>
          <v-icon>mdi-home</v-icon>
        </v-avatar>
        {{ item.volunteer.city }},
        {{ item.volunteer.state.abbreviation }}
      </v-chip>

      <v-chip v-if="item.confirmed" class="mr-2 mb-2" color="blue-grey" text-color="white">
        <v-avatar left>
          <v-icon>mdi-check</v-icon>
        </v-avatar>
        <span class="capitalize">Confirmed</span>
      </v-chip>

      <v-chip v-if="item.volunteer.phone" class="mr-2 mb-2" color="blue-grey" text-color="white">
        <v-avatar left>
          <v-icon>mdi-phone</v-icon>
        </v-avatar>
        <span v-if="item.volunteer.phone_intl_code">{{ item.volunteer.phone_intl_code }}-</span>
        <span>{{ item.volunteer.phone }}</span>
      </v-chip>

      <v-chip v-if="item.roll_call" class="mr-2 mb-2" color="blue-grey" text-color="white">
        <v-avatar left>
          <v-icon>mdi-script-text</v-icon>
        </v-avatar>
        <span class="capitalize">{{ item.roll_call }}</span>
      </v-chip>
    </v-card-text>

    <v-card-text v-if="shift" class="pt-0">
      <RollCallOptions
        :volunteerShiftID="item.id"
        :rollCallValue="item.roll_call"
        :shiftID="shift.id"
        :color="color"
      />
    </v-card-text>

    <v-card-actions>
      <v-select
        :value="currentStatus"
        :items="statusOptions"
        outlined dense
        label="Status"
        class="mr-2"
        @input="updateStatus"
      />
    </v-card-actions>

    <v-btn class="delete-item" icon @click="showDeleteDialog(item.id)" title="Delete">
      <v-icon>mdi-delete</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import RollCallOptions from "@/components/Volunteer/RollCallOptions";

export default {
  components: {
    RollCallOptions,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    shift: {
      type: Object,
      required: false,
    },
    showDeleteDialog: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      statusOptions: [
        { text: 'Accepted', value: 'accepted' },
        { text: 'Pending', value: 'pending' },
        { text: 'Waitlisted', value: 'waitlisted' },
        { text: 'Declined', value: 'declined' },
      ],
    };
  },
  computed: {
    currentStatus() {
      if (this.item.accepted) return 'accepted';
      if (this.item.waitlist) return 'waitlisted';
      if (this.item.declined) return 'declined';
      if (this.item.pending) return 'pending';
      return '';
    },
  },
  methods: {
    updateStatus(newStatus) {
      this.$emit('statusChange', this.item, newStatus);
    },
  },
}
</script>
