<template>
    <div class="flex-shrink-1 mr-6">
        <v-btn @click="loader = 'loadingAccepted'; addVolunteerToShift(item.id, shift.id, 'accepted');" class="button-accepted mx-1" :loading="loadingAccepted" :disabled="loadingAccepted">
            <v-progress-circular indeterminate color="white" v-if="loadingAccepted === true"></v-progress-circular>
            <v-icon left>mdi-plus-circle</v-icon>
            Accept
        </v-btn>

        <v-btn indigo @click="loader = 'loadingPending'; addVolunteerToShift(item.id, shift.id, 'pending');" class="button-pending mx-1" color="indigo"  :loading="loadingPending" :disabled="loadingPending">
            <v-progress-circular indeterminate color="white" v-if="loadingPending === true"></v-progress-circular>
            <v-icon left>mdi-plus-circle</v-icon>
            Pending
        </v-btn>

        <v-btn
            @click="loader = 'loadingWaitlist'; addVolunteerToShift(item.id, shift.id, 'waitlist');"
            class="button-pending mx-1"
            color="teal"
            :loading="loadingWaitlist"
            :disabled="loadingWaitlist"
        >
            <v-progress-circular indeterminate color="white" v-if="loadingWaitlist === true"></v-progress-circular>
            <v-icon left>mdi-plus-circle</v-icon>
            Waitlist
        </v-btn>
    </div>
</template>


<script>
export default {
    name: "ShiftVolunteerAddButtons",
    props: {
      item: Object,
      shift: Object,
    },
    data() {
        return {
            loader: null,
            loadingAccepted: false,
            loadingPending: false,
            loadingWaitlist: false,
        }
    },
    methods: {
        async addVolunteerToShift(volunteerID, shiftID, type) {
            const data = {
                volunteer_id: volunteerID,
                shift_id: shiftID,
            };

            if (type === 'accepted') {
                data.accepted = true;
            } else if (type === 'pending') {
                data.pending = true;
            } else if (type === 'waitlist') {
                data.waitlist = true;
            }

            await window.axios.post("/volunteershifts", data);

            this.$store.dispatch("shifts/getShift", shiftID).then( () => {
                this.loadingAccepted = false;
                this.loadingPending = false;
                this.loadingWaitlist = false;
            })
        },
    },
    watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]
      },
    },
}
</script>

<style scoped>
.custom-loader {
animation: loader 1s infinite;
display: flex;
}
@-moz-keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
@-o-keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}


.button-pending {
    color: #ffffff;
}

.button-accepted {
    color: #ffffff;
    background-color: #2196f3 !important;
    border: 1px solid #2196f3 !important;
}

.button-accepted.v-btn--loading {
    border: 1px solid #D7D7D7 !important;
}
</style>
