<template>
  <div>
    <v-row>
      <v-toolbar flat class="grey lighten-4 mb-5">
        <v-toolbar-title>
          <strong v-if="shift.event.teams">{{ shift.name }}</strong>
          <strong v-else><span v-if="shift.name">{{ shift.name }} - </span> {{ shift.start_date | moment("dddd MMM D, YYYY") }}</strong>

          <strong v-if="shift.venue && shift.venue.state && shift.venue.city">
            - {{ shift.venue.city }}, {{ shift.venue.state.abbreviation }}
          </strong>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-toolbar-items class="hidden-sm-and-down align-center">
          <v-menu offset-y open-on-hover>
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                Volunteers
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click.stop="addVolunteerToshiftDialog = true">
                Add Volunteer
              </v-list-item>
            </v-list>
          </v-menu>

          <v-dialog v-model="addVolunteerToshiftDialog"  v-if="volunteers" >
            <v-card style="overflow-x: hidden; overflow-y: hidden; padding-bottom: 40px;">
              <v-card-title class="text-center">Add Volunteer</v-card-title>

              <v-row v-if="volunteers">
                <v-col cols="12">
                  <v-data-iterator
                    :items-per-page="50"
                    :items="volunteers.data"
                    :page="page"
                    no-results-text=""
                    no-data-text=""
                    hide-default-footer
                  >
                    <template v-slot:header>
                      <v-toolbar flat class="mb-8 align-center">

                        <v-text-field
                          v-model="search"
                          clearable
                          light
                          outlined
                          solo
                          flat
                          hide-details
                          append-inner-icon="mdi-magnify"
                          label="Search"
                          @keyup.enter="searchFuzzy"
                          @click:clear="clearClick"
                        >
                        </v-text-field>
                        <strong class="pl-2">{{ volunteers.total }} Volunteers</strong>

                        <v-spacer></v-spacer>
                      </v-toolbar>
                    </template>

                    <template v-slot:default="props">
                      <v-toolbar flat class="search-result" v-for="item in props.items" :key="item.name">
                        <shift-volunteer-add-buttons :item="item" :shift="shift"></shift-volunteer-add-buttons>
                        <!--
                        <div class="flex-shrink-1 mr-6">
                          <v-btn @click="addVolunteerToShift(item.id, shift.id, true)" class="mr-3 button-accepted">
                            <v-icon left>mdi-plus-circle</v-icon>
                            Accept
                          </v-btn>

                          <v-btn indigo @click="addVolunteerToShift(item.id, shift.id, false)" class="button-pending" color="indigo">
                            <v-icon left>mdi-plus-circle</v-icon>
                            Pending
                          </v-btn>
                        </div>
                        -->

                        <v-toolbar-title class="flex-grow-1">
                          <router-link
                            :to="{ name: 'VolunteerShow', params: { id: item.id } }"
                            class="flex flex-grow-1"
                            >
                            <strong class="captalize">{{ item.first_name }} {{ item.last_name }}</strong>
                          </router-link>

                          <div v-if="item.created_at || item.city || item.tshirt_size" class="mt-2 d-flex flex-wrap">
                            <v-chip class="mr-2 mb-2" color="blue-grey" text-color="white" v-if="item.created_at">
                              <v-avatar left>
                                <v-icon>mdi-calendar</v-icon>
                              </v-avatar>
                              {{ item.created_at | moment("MMM D, YYYY") }}
                            </v-chip>
                            <v-menu
                              :open-on-hover="true"
                              :offset-y="true"
                              class="tooltip-menu"
                              max-width="99%"
                            >
                              <template v-slot:activator="{ on }">
                                <v-chip
                                  class="mr-2 mb-2"
                                  color="blue-grey"
                                  text-color="white"
                                  v-on="on"
                                  v-if="item.city && item.state"
                                >
                                  <v-avatar left>
                                    <v-icon>mdi-home</v-icon>
                                  </v-avatar>
                                  {{ item.city }}, {{ item.state.abbreviation }}
                                </v-chip>
                              </template>
                              <div class="tooltip">
                                <span>
                                  <p class="subtitle my-2" v-if="item.state">
                                    {{ item.address_line_1 }} {{ item.address_line_2
                                    }}<br />
                                    {{ item.city }}, {{ item.state.abbreviation }}
                                    {{ item.postal_code }}
                                    <v-btn
                                      text
                                      class="px-0 py-1 primary--text btn-tooltip"
                                      @click.stop="showDialog(item.email)"
                                    >
                                      {{ item.email }}
                                    </v-btn>
                                    {{ formatPhoneNumber(item.phone) }}
                                  </p>
                                </span>
                              </div>
                            </v-menu>
                            <v-chip
                              class="mr-2 mb-2"
                              color="blue-grey"
                              text-color="white"
                              v-if="item.tshirt_size"
                            >
                              <v-avatar left>
                                <v-icon>mdi-tshirt-crew</v-icon>
                              </v-avatar>
                              <span class="capitalize">{{ item.tshirt_size }}</span>
                            </v-chip>
                            <v-chip class="mr-2 mb-2" color="blue-grey" text-color="white" v-if="item.phone">
                              <v-avatar left>
                                <v-icon>mdi-phone</v-icon>
                              </v-avatar>
                              <span v-if="item.phone_intl_code">{{ item.phone_intl_code }}-</span>
                              <span>{{ item.phone }}</span>
                            </v-chip>
                          </div>
                        </v-toolbar-title>
                      </v-toolbar>
                    </template>

                    <template v-slot:footer v-if="volunteers">
                      <div class="text-xs-center">
                        <v-pagination
                          v-model="page"
                          :length="volunteers.last_page"
                          :total-visible="15"
                        ></v-pagination>
                      </div>
                    </template>
                  </v-data-iterator>
                </v-col>
              </v-row>
            </v-card>
          </v-dialog>

          <v-menu offset-y open-on-hover>
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                Settings
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                :to="{ name: 'ShiftSettings', params: { id: shift.id } }"
              >
                <v-list-item-title>Edit Shift</v-list-item-title>
              </v-list-item>
              <v-list-item
                :to="{
                  name: 'ShiftEmailTemplates',
                  params: { event_id: shift.event.id, shift_id: shift.id },
                }"
              >
                <v-list-item-title>Email Templates</v-list-item-title>
              </v-list-item>
              <v-list-item
                :to="{
                  name: 'MetricsIndex',
                  params: { event_id: shift.event.id, shift_id: shift.id },
                }"
              >
                <v-list-item-title>Metrics</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn text @click="notesDialog = true">Notes</v-btn>
          <v-btn text @click="tagsDialog = true">Tags</v-btn>
          <v-dialog
            width="500px"
            style="background-color: #fff;"
            class="dialog"
            v-model="dialog"
            persistent
          >
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">Report</v-btn>
            </template>
            <v-card>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                v-on:submit.prevent="submit"
              >
                <v-container>
                  <v-row>
                    <v-col><h2>Report Shift Totals</h2></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <div
                        v-for="(metric, index) in metricShiftData"
                        :key="index"
                      >
                        <v-text-field
                          v-model="metricShiftData[index].value"
                          :label="metric.name"
                          type="number"
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn
                        class="mr-4"
                        color="primary"
                        dark
                        @click="updateMetrics"
                        >Save</v-btn
                      >
                      <v-btn
                        depressed
                        class="mr-3 btn-secondary"
                        @click="dialog = false"
                        >Cancel</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card>
          </v-dialog>
          <v-btn v-if="showShiftEditIcon" icon @click="editShiftChange()">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn v-if="!shift.archived" icon @click="archiveShift" title="Archive">
            <v-icon>mdi-archive-arrow-down</v-icon>
          </v-btn>
          <v-btn v-if="shift.archived" icon @click="unarchiveShift" title="Unarchive">
            <v-icon>mdi-archive-arrow-up</v-icon>
          </v-btn>
          <v-btn v-if="showShiftDeleteIcon" icon @click="deleteDialog = true">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-toolbar-items>

        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" class="hidden-md-and-up">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title
                ><router-link
                  :to="{ name: 'ShiftShow', params: { id: shift.id } }"
                  >Volunteers</router-link
                ></v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-menu offset-y open-on-hover>
                  <template v-slot:activator="{ on }">
                    <a text v-on="on">
                      Settings
                    </a>
                  </template>
                  <v-list>
                    <v-list-item
                      :to="{ name: 'ShiftSettings', params: { id: shift.id } }"
                    >
                      <v-list-item-title>Edit Shift</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      :to="{
                        name: 'ShiftEmailTemplates',
                        params: {
                          event_id: shift.event.id,
                          shift_id: shift.id,
                        },
                      }"
                    >
                      <v-list-item-title>Email Templates</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      :to="{
                        name: 'MetricsIndex',
                        params: {
                          event_id: shift.event.id,
                          shift_id: shift.id,
                        },
                      }"
                    >
                      <v-list-item-title>Metrics</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title
                ><a @click="notesDialog = true" href="#"
                  >Notes</a
                ></v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title
                ><a @click="tagsDialog = true" href="#"
                  >Tags</a
                ></v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-dialog
                  width="500px"
                  style="background-color: #fff;"
                  class="dialog"
                  v-model="dialog"
                  persistent
                >
                  <template v-slot:activator="{ on }">
                    <a text v-on="on">Report</a>
                  </template>
                  <v-card>
                    <v-form
                      ref="form"
                      v-model="valid"
                      lazy-validation
                      v-on:submit.prevent="submit"
                    >
                      <v-container>
                        <v-row>
                          <v-col><h2>Report Shift Totals</h2></v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <div
                              v-for="(metric, index) in metricShiftData"
                              :key="index"
                            >
                              <v-text-field
                                v-model="metricShiftData[index].value"
                                :label="metric.name"
                                type="number"
                              ></v-text-field>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-btn
                              class="mr-4"
                              color="primary"
                              dark
                              @click="updateMetrics"
                              >Save</v-btn
                            >
                            <v-btn
                              depressed
                              class="mr-3 btn-secondary"
                              @click="dialog = false"
                              >Cancel</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-row>
    <v-chip
      class="mr-2 mb-2"
      color="blue-grey"
      text-color="white"
      v-if="!shift.event.teams"
    >
      <v-avatar left>
        <v-icon>mdi-alarm</v-icon>
      </v-avatar>
      <strong class="pr-1">Doors:</strong>
      {{ shift.doors }}
    </v-chip>
    <v-chip
      class="mr-2 mb-2"
      color="blue-grey"
      text-color="white"
      v-if="!shift.event.teams"
    >
      <v-avatar left>
        <v-icon>mdi-alarm</v-icon>
      </v-avatar>
      <strong class="pr-1">Check-In:</strong>
      {{ shift.check_in }}
    </v-chip>
    <v-chip
      class="mr-2 mb-2"
      color="blue-grey"
      text-color="white"
      v-if="shift.venue"
    >
      <v-avatar left>
        <v-icon>mdi-home</v-icon>
      </v-avatar>
      {{ formatVenueName(shift.venue) }}
    </v-chip>
    <div v-if="shift.event.teams" class="mt-2">
      <v-menu
        :open-on-hover="true"
        :offset-y="true"
        class="tooltip-menu"
        max-width="99%"
      >
        <template v-slot:activator="{ on }">
          <v-chip
            class="mr-2 mb-2"
            color="blue-grey"
            text-color="white"
            v-on="on"
          >
            <v-avatar left>
              <v-icon>mdi-alarm</v-icon>
            </v-avatar>
            <strong class="pr-1">Schedule</strong>
          </v-chip>
        </template>
        <div class="tooltip">
          <span v-if="shift.event.teams">
            <ul class="schedule-items-list">
              <li
                v-for="scheduleItem in shift.shift_schedules"
                :key="scheduleItem.id"
              >
                <strong>{{
                  scheduleItem.start_date | moment("dddd MMM D, YYYY")
                }}</strong
                ><br />Doors: {{ scheduleItem.doors }} | Check:
                {{ scheduleItem.check_in }}
              </li>
            </ul>
          </span>
        </div>
      </v-menu>
      <v-chip
        class="mr-2 mb-2"
        color="blue-grey"
        text-color="white"
        v-if="shift.venue"
      >
        <v-avatar left>
          <v-icon>mdi-home</v-icon>
        </v-avatar>
        {{ formatVenueName(shift.venue) }}
      </v-chip>
    </div>

    <div style="display: inline-block; float: right;">
      <v-select
        :items="exportItems"
        v-model="exportType"
        item-text="label"
        item-value="value"
        class="mr-3"
        @change="exportCSV($event)"

        >
      </v-select>
    </div>

    <NotesDialog
      :active="notesDialog"
      :noteableId="shift.id"
      noteableType="Shift"
      @close="notesDialog = false"
    ></NotesDialog>
    <TagsDialog
      :active="tagsDialog"
      :tagableId="shift.id"
      tagableType="Shift"
      @close="tagsDialog = false"
    ></TagsDialog>
    <DeleteDialog
      :itemId="shift.id"
      :active="deleteDialog"
      redirect="EventShifts"
      :redirectParams="{ id: shift.event.id }"
      path="shifts/deleteShift"
      @close="deleteDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NotesDialog from "@/components/Note/NotesDialog";
import TagsDialog from "@/components/Tag/TagsDialog";
import DeleteDialog from "@/components/DeleteDialog";
import ShiftVolunteerAddButtons from "@/components/Shift/ShiftVolunteerAddButtons";

export default {
  name: "ShiftHeader",

  components: {
    NotesDialog,
    TagsDialog,
    DeleteDialog,
    ShiftVolunteerAddButtons,
  },

  props: {
    shift: Object,
    shifts: Array,
    viewOnly: Boolean,
    showShiftEditIcon: {
      type: Boolean,
      default: false,
    },
    showShiftDeleteIcon: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loader: null,
      loading: false,
      page: 1,
      search: "",
      addVolunteerToshiftDialog: false,
      deleteDialog: false,
      postData: {
        metricsPostData: [],
      },
      exportType: { label: 'Export...', value: null },
      exportItems: [
        { label: 'Export...', value: null },
        { label: 'Export Shift Contacts', value: 'shift-export-contacts' },
        { label: 'Export Shift Nonprofits', value: 'shift-export-nonprofits' },
        { label: 'Export Shift Volunteers', value: 'shift-export-volunteers' },
      ],
      metricShiftData: [],
      tagsDialog: false,
      notesDialog: false,
      dialog: false,
      valid: false,
      query: {
        startDate: null,
        endDate: null,
        stateFilter: null,
        sizeFilter: null,
        eventFilter: null,
        artistFilter: null,
        tagFilter: null,
        ids: [],
        allSelected: false,
      },
    };
  },

  computed: {
    ...mapGetters({
      metrics: "metrics/metrics",
      metricValues: "metric_values/metric_values",
      volunteers: "volunteers/volunteers",
    }),
  },

  methods: {
    clear() {
      this.query.startDate = null;
      this.query.endDate = null;
      this.query.stateFilter = null;
      this.query.sizeFilter = null;
      this.query.eventFilter = null;
      this.query.artistFilter = null;
      this.query.tagFilter = null;
      this.page = 1;
      this.submit(true);
    },

    clearClick() {
      this.search = "";
      this.searchFuzzy();
    },
    formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }

      return null;
    },
    editShiftChange() {
      // eslint-disable-next-line vue/no-mutating-props
      this.viewOnly = !this.viewOnly;
      this.$emit("updateShift", this.viewOnly);
    },
    updateMetrics() {
      let payload = {
        metrics: this.metricShiftData,
      };

      if (this.$refs.form.validate()) {
        this.$store.dispatch("metric_values/updateMetricValues", payload);
        this.dialog = false;
      }
    },

    updateShift() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("shifts/updateShift", this.shift);
        this.dialog = false;
      }
    },

    archiveShift() {
      const data = {
        id: this.shift.id,
        archived: 1
      }
      this.$store.dispatch("shifts/updateShift", data);
    },
    unarchiveShift() {
      const data = {
        id: this.shift.id,
        archived: 0
      }
      this.$store.dispatch("shifts/updateShift", data);
    },
    searchFuzzy() {
      this.query.startDate = null;
      this.query.endDate = null;
      this.query.stateFilter = null;
      this.query.sizeFilter = null;
      this.query.eventFilter = null;
      this.query.artistFilter = null;
      this.query.tagFilter = null;
      this.page = 1;

      this.$store.dispatch("volunteers/searchFuzzy", "q=" + this.search);
    },

    async addVolunteerToShift(volunteerID, shiftID, accepted) {

      const data = {
        volunteer_id: volunteerID,
        shift_id: shiftID,
      };

      if( accepted === true ) {
        data.accepted = true;
      }
      else {
        data.pending = true;
      }

      const result = await window.axios.post("/volunteershifts", data);

      if(result.status === 201) {
        this.$store.dispatch("shifts/getShift", shiftID);
      }
    },

    checkAll() {
      this.query.ids = [];

      if (this.query.allSelected) {
        for (let v in this.volunteers.data) {
          this.query.ids.push(this.volunteers.data[v].id);
        }
      }
    },

    updateCheckAll() {
      this.query.allSelected = false;
    },

    submit(reset_pagination) {
      this.$store.dispatch("global/updateLoading", true, { root: true });

      let pageFilter = this.page != null ? "&page=" + this.page : "";

      if (reset_pagination) {
        pageFilter = "";
        this.page = 1;
        this.search = null;
      }

      let filter = this.getFilter() + pageFilter;

      if (this.search)
        this.$store.dispatch("volunteers/searchFuzzy", "q=" + this.search + "&page=" + pageFilter);
      else
        this.$store.dispatch("volunteers/searchVolunteers", filter);
    },
    getFilter() {
      let stateFilter =
        this.query.stateFilter != null
          ? "filter[state_id]=" + this.query.stateFilter
          : "filter[state_id]=";
      let sizeFilter =
        this.query.sizeFilter != null
          ? "&filter[tshirt_size]=" + this.query.sizeFilter
          : "";
      let eventFilter =
        this.query.eventFilter != null
          ? "&filter[VolunteerShifts.shift.event_id]=" + this.query.eventFilter
          : "";
      let artistFilter =
        this.query.artistFilter != null
          ? "&filter[VolunteerShifts.shift.artist_id]=" +
            this.query.artistFilter
          : "";
      let tagFilter =
        this.query.tagFilter != null
          ? "&filter[tags.id]=" + this.query.tagFilter
          : "";
      let dateFilter = "";
      if (this.query.startDate && this.query.endDate)
        dateFilter =
          "&filter[volunteer_shift_created_between]=" +
          this.query.startDate +
          "," +
          this.query.endDate;
      else if (this.query.startDate || this.query.endDate) {
        if (this.query.startDate)
          dateFilter =
            "&filter[volunteer_shift_created_after]=" + this.query.startDate;
        else
          dateFilter =
            "&filter[volunteer_shift_created_before]=" + this.query.endDate;
      }

      return (
        stateFilter +
        sizeFilter +
        eventFilter +
        artistFilter +
        tagFilter +
        dateFilter
      );
    },
    exportCSV(value) {
      // The default option is selected
      if(value === null) {
        return;
      }

      window.location.href = process.env.VUE_APP_API_URL + `/${value}?id=${this.shift.id}`;
      this.$store.dispatch("global/resetObjects");
    },
  },

  watch: {
    page(val, oldVal) {
      if (val != oldVal) {
        this.page = val;
        this.submit(false);
      }
    },
    metrics() {
      //load all shift metric fields to metricData array,
      //if the field is already in the array then update it
      for (let i = 0; i < this.metrics.length; i++) {
        if (this.metrics[i].metric_type == "Shift") {
          if (
            !this.metricShiftData.some(
              (item) =>
                item.metric_id === this.metrics[i].id &&
                this.metrics[i].metric_type == "Shift"
            )
          ) {
            let id = null;
            if (this.metrics[i].metric_type == "Event")
              id = this.shift.event.id;
            else id = this.shift.id;

            this.metricShiftData.push({
              name: this.metrics[i].name,
              metric_id: this.metrics[i].id,
              metricable_id: id,
              metricable_type: this.metrics[i].metric_type,
              value: 0,
            });
          }
        }
      }
    },

    metricValues() {
      //load all event metric_values fields to metricData array
      //if the field is already in the array then update it
      for (let i = 0; i < this.metricValues.length; i++) {
        if (
          this.metricValues[i].metricable_type == "Shift" &&
          this.metricValues[i].metric.active
        ) {
          if (
            this.metricShiftData.some(
              (item) =>
                item.metric_id === this.metricValues[i].metric_id &&
                item.metricable_id === this.metricValues[i].metricable_id &&
                item.metricable_type === this.metricValues[i].metricable_type
            )
          ) {
            for (let j = 0; j < this.metricShiftData.length; j++) {
              if (
                this.metricShiftData[j].metric_id ==
                  this.metricValues[i].metric_id &&
                this.metricShiftData[j].metricable_id ==
                  this.metricValues[i].metricable_id &&
                this.metricShiftData[j].metricable_type ==
                  this.metricValues[i].metricable_type
              ) {
                this.metricShiftData[j].value = this.metricValues[i].value;
              }
            }
          } else {
            this.metricShiftData.push({
              name: this.metricValues[i].metric.name,
              metric_id: this.metricValues[i].metric_id,
              metricable_id: this.metricValues[i].metricable_id,
              metricable_type: this.metricValues[i].metricable_type,
              value: this.metricValues[i].value,
            });
          }
        }
      }
    },
  },

  mounted() {
    this.$store.dispatch("volunteers/getVolunteers");

    this.$store.dispatch(
      "metric_values/searchMetricValuesNoPaginate",
      "filter[metricable_id]=" +
        this.shift.id +
        "&filter[metricable_type]=Shift"
    );

    if (this.$route.params.filter == "pending") {
      this.query.pendingFilter = true;
      this.submit(true);
    }
  },
};
</script>

<style scoped>
.dialog {
  background-color: #fff !important;
}
.teams {
  display: flex;
  flex-grow: 1;
}
.schedule-items-list {
  list-style: none;
  padding-left: 0 !important;
}
.schedule-items-list li {
  margin-bottom: 1rem !important;
}
.schedule-items-list li:last-child {
  margin-bottom: 0 !important;
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}
</style>
